@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
	margin: 0;
	padding: 0;
	font-family: "Rubik", sans-serif;
}
.test {
	border: 1px solid black;
}

.row {
	display: flex;
	flex-direction: row;
}

.col {
	display: flex;
	flex-direction: column;
}
button {
	&:disabled {
		background-color: /*rgba(204, 222, 249, 1)*/ rgb(204, 249, 228);
		color: white;
		&:hover {
			background-color: /*rgba(204, 222, 249, 1)*/ rgb(204, 249, 228);
		}
	}
}
select{
	&:disabled{
		border: 1px solid red;
	}
}
